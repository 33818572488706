export interface UnifyAIWelcome {
    accelerate_innovation:  UnifyAIAccelerateInnovation[];
    why_unifyAI:            WhyUnifyAI[];
    enterprise_type:        EnterpriseType[];
    unifyAI_benefits:      UnifyAIBenefit[];
    status_code:            number;
   
}

export interface UnifyAIAccelerateInnovation {
    id:      number;
    logo?:   string;
    status:  number;
    regDate: string;
    modDate: string;
    image?:  string;
    description?:string,
    position?:any
}

export interface EnterpriseType {
    id:       number;
    heading:  string;
    ponter_1: string;
    ponter_2: string;
    ponter_3: string;
    ponter_4: string;
    ponter_5: string;
    regDate:  string;
    modDate:  string;
}
export interface WhyUnifyAI {
    id:      number;
    heading: string;
    regDate: string;
    modDate: string;
}

export interface UnifyAIBenefit {
    id: number;
    benefits_title_id: number;
    description: string;
    image: string;
    status: number;
    regDate: string;
    modDate: string;
    benefits_title: BenefitsTitle;
}

export interface BenefitsTitle {
    heading: string;
    id: number;
}
