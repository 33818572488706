export interface WelcomeData {
    second_section:         SecondSection;
    third_section:          ThirdSection;
    powering_ai_innovation: PoweringAIInnovation;
    video_links:            string;
    company_logos:          AITechnology[];
    ai_technologies:        AITechnology[];
}

export interface AITechnology {
    id:      any;
    image:   any;
    status:  any;
    regDate: any;
    modDate: any;
}

export interface PoweringAIInnovation {
    solution_domain: SolutionDomain[];
}

export interface SecondSection {
    heading:     string;
    sub_heading: string;
    slider:      AITechnology[];
}

export interface ThirdSection {
    heading:     string;
    sub_heading: string;
    cards:       Card[];
}

export interface Card {
    id:          number;
    heading:     string;
    description: string;
    image:       string;
    status:      number;
    regDate:     string;
    modDate:     string;
}

export interface SolutionDomain{
    id:number
    image:string
    isactive_home:number
    modDate:string
    name:string
    regDate:string
    status:number
    useCasesDomain: UseCasesDomain[]
}

export interface UseCasesDomain{
    useCaseId: number
    heading: string
    solution_domain_id:number
    challenge_description: string
    solution_description: string
    benefit_description: string
    short_description: string
    imgSrc: string
    status: number
    isactive_home:number
    regDate: string
    modDate: string
}